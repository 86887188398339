import { TadaAyanaAPI } from ".";

export default {
  getConfig({ key, action = "" }) {
    return TadaAyanaAPI.get(`/config/${key}`);
  },
  putConfig({ key, value, isEnabled = 1, action = "", pageName = "" }) {
    return TadaAyanaAPI.put(
      `/config/${key}`,
      {
        value: value,
        is_enabled: isEnabled
      },
      {
        headers: { Action: action, Page: pageName == 'transaction' ? `email-setting-key-transaction_update` : 'email-setting-key-blast_update' }
      }
    );
  }
};
