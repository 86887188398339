import UsersAPI from "../../api/users";

const state = {
  users: []
};

const getters = {};

const actions = {
  async getUsers({ commit }) {
    UsersAPI.getUsers()
      .then(response => {
        const users = response.data.data || [];
        commit("USER_SUCCESS", users);
      })
      .catch(error => {
        console.log(error);
      });
  }
};

const mutations = {
  USER_SUCCESS: (state, users) => {
    state.users = users;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
