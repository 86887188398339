import { TadaAyanaAPI } from ".";

export default {
  getVouchers({
    page = 1,
    limit = 9,
    name = "",
    type = "",
    category = "",
    property = "",
    action = "",
    pageName = "",
    pointFrom = "",
    pointTo = ""
  }) {
    let config = null;

    if (action != null && action.length > 0) {
      config = { headers: { Action: action } };
    }

    if (pageName != null && pageName.length > 0) {
      config.headers["Page"] = pageName;
    }

    return TadaAyanaAPI.get(
      `/inventory/voucher?page=${page}&per_page=${limit}&name=${name}&type=${type}&category_id=${category}&property=${property}&point_from=${pointFrom}&point_to=${pointTo}&lang=EN`,
      config
    );
  },
  getVoucher({ id, action = "", pageName = "" }) {
    return TadaAyanaAPI.get(`/inventory/voucher/${id}`, {
      headers: { Action: action, Page: pageName }
    });
  },
  postVoucher({ data, action = "" }) {
    return TadaAyanaAPI.post("/inventory/voucher", data, {
      headers: { Action: action }
    });
  },
  putVoucher({ data, action = "" }) {
    return TadaAyanaAPI.put(`/inventory/voucher/${data.id}`, data, {
      headers: { Action: action }
    });
  },
  deleteVouchers({ ids, action = "" }) {
    return TadaAyanaAPI.post(`/inventory/voucher/delete_by_ids`, ids, {
      headers: { Action: action }
    });
  }
};
