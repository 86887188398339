import { TadaAyanaAPI } from ".";

export default {
  getPointAdjustmentList({ filter, source, action = "" }) {
    const filterType = filter.type ? `&type=${filter.type}` : "";

    return TadaAyanaAPI.get(
      `/point-adjustment/list?page=${filter.page}&search=${filter.search}${filterType}`,
      {
        cancelToken: source.token,
        headers: { Action: action, Page: `point-adjusment_list` }
      }
    );
  },
  searchByConfirmationNo(confirmationNo) {
    return TadaAyanaAPI.get(`/point-adjustment/search-by-confirmation/${confirmationNo}`)
  },
  getPointAdjustmentDetail({ id, action = "" }) {
    return TadaAyanaAPI.get(`/point-adjustment/${id}`,{
      headers: { Action: action, Page: `point-adjusment_detail` }
    });
  },
  updatePointAdjustment({ id, data, action = "" }) {
    return TadaAyanaAPI.put(`/point-adjustment/${id}`, data, {
      headers: { Action: action, Page: `point-adjusment_update` }
    });
  },
  getPlaceOfVisit() {
    return TadaAyanaAPI.get(`/property/all?lang=EN`);
  },
  searchGuest(name) {
    return TadaAyanaAPI.get(`/member/list?&page=1&search=${name}`);
  },
  createPointAdjustment({ data, action = "" }) {
    return TadaAyanaAPI.post(`/point-adjustment`, data, {
      headers: { Action: action, Page: `point-adjusment_create` }
    });
  },
  deletePointAdjustment({ id, action = "" }) {
    return TadaAyanaAPI.delete(`/point-adjustment/${id}`, {
      headers: { Action: action, Page: `point-adjusment_delete` }
    });
  },
  approvePointAdjustment({ id, action = "" }) {
    return TadaAyanaAPI.put(`/point-adjustment/approval/${id}`, null, {
      headers: { Action: action, Page: `point-adjusment_approval` }
    });
  }
};
