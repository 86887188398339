import { TadaAyanaAPI } from ".";

export default {
  getMemberLevelBonus() {
    return TadaAyanaAPI.get("/member/level-bonus/all", {
      headers: { Page: 'level-bonus_all' }
    });
  },
  getDetailLevelBonus(level) {
    return TadaAyanaAPI.get(`/member/level-bonus/${level}`, {
      headers: { Page: 'level-bonus_detail' }
    });
  },
  updateMemberLevelBonus({ level, data, action = "" }) {
    return TadaAyanaAPI.put(`/member/level-bonus/${level}`, data, {
      headers: { Action: action, Page: 'level-bonus_update' }
    });
  }
};
