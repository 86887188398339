import { TadaAyanaAPI } from ".";

export default {
  getAll({ action = "" }) {
    return TadaAyanaAPI.get(`/point-rule/basic/all`, {
      headers: { Action: action, Page: 'point-rule-basic_all' }
    });
  },
  getDetail({ id, action = "" }) {
    return TadaAyanaAPI.get(`/point-rule/basic/${id}`, {
      headers: { Action: action, Page: 'point-rule-basic_detail' }
    });
  },
  postBasicPoint({ data, action = "" }) {
    return TadaAyanaAPI.post("/point-rule/basic", data, {
      headers: { Action: action, Page: 'point-rule-basic_create' }
    });
  },
  putBasicPoint({ data, action = "" }) {
    return TadaAyanaAPI.put(`/point-rule/basic/${data.id}`, data, {
      headers: { Action: action, Page: 'point-rule-basic_update' }
    });
  },
  deleteBasicPoint({ id, action = "" }) {
    return TadaAyanaAPI.delete(`/point-rule/basic/${id}`, {
      headers: { Action: action, Page: 'point-rule-basic_delete' }
    });
  }
};
