import PropertiesAPI from "../../api/properies";

const state = {
  propertyAll: []
};

const getters = {};

const actions = {
  async getPropertyAll({ commit }) {
    PropertiesAPI.getPropertyAll()
      .then(response => {
        const data = response.data.data || [];
        commit("GET_PROPERTY_ALL_SUCCESS", data);
      })
      .catch(error => {
        console.log(error);
      });
  }
};

const mutations = {
  GET_PROPERTY_ALL_SUCCESS: (state, data) => {
    state.propertyAll = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
