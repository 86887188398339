import BonusPointAPI from "../../api/bonusPoint";

const state = {
  bonusPointAll: [],
  bonusPoint: {
    id: null,
    name: "",
    type: "",
    value: 0,
    max_point_per_user: 10,
    start_date: "",
    end_date: "",
    conditions: []
  },
  time: {
    startTime: "",
    endTime: ""
  }
};

const getters = {
  showButtonAndAtIndex: (state, getters, rootState) => indexRow => {
    const condition = state.bonusPoint.conditions[indexRow];
    const lastEvaluator = condition.evaluators[condition.evaluators.length - 1];
    return !!(
      lastEvaluator.code &&
      condition.evaluators.length < rootState.customFields.customFieldAll.length
    );
  },
  disableOptionFieldType: state => (indexRow, fieldType) => {
    return (
      state.bonusPoint.conditions[indexRow].evaluators
        .map(item => item.code)
        .filter(item => item === fieldType).length > 0
    );
  },
  inputFieldType: state => (indexRow, indexItem) => {
    return state.bonusPoint.conditions[indexRow].evaluators[indexItem].type;
  }
};

const actions = {
  getBonusPointAll({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      BonusPointAPI.getAll()
        .then(response => {
          const data = response.data.data || [];
          commit("GET_BONUS_POINT_ALL_SUCCESS", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getBonusPoint({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      BonusPointAPI.getDetail({
        id: payload.id,
        action: `Admin: ${rootState.auth.name} view ${payload.module}`
      })
        .then(response => {
          const data = response.data.data || [];
          commit("GET_BONUS_POINT_SUCCESS", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  saveBonusPoint({ state, rootState }) {
    const bonusPointStartDate = state.bonusPoint.start_date;
    const bonusPointStartTime = state.time.startTime;
    const bonusPointEndDate = state.bonusPoint.end_date;
    const bonusPointEndTime = state.time.endTime;

    const startDate = `${bonusPointStartDate}T${bonusPointStartTime}+07:00`;
    const endDate = `${bonusPointEndDate}T${bonusPointEndTime}+07:00`;
    var data = state.bonusPoint;
    data.start_date = startDate;
    data.end_date = endDate;

    if (state.bonusPoint.id !== null) {
      return new Promise((resolve, reject) => {
        BonusPointAPI.putBonusPoint({
          data,
          action: `Admin: ${rootState.auth.name} update bonus point rule with id: ${data.id}, name: ${data.name}`
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        BonusPointAPI.postBonusPoint({
          data,
          action: `Admin: ${rootState.auth.name} create bonus point rule with id: ${data.id}, name: ${data.name}`
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  deleteBonusPoint({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      BonusPointAPI.deleteBonusPoint({
        id: payload.id,
        action: `Admin: ${rootState.auth.name} delete bonus point rule with id: ${payload.id}, name: ${payload.name}`
      })
        .then(response => {
          commit("DELETE_BONUS_POINT_SUCCESS", payload.id);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  GET_BONUS_POINT_ALL_SUCCESS: (state, payload) => {
    state.bonusPointAll = payload;
  },
  GET_BONUS_POINT_SUCCESS: (state, payload) => {
    state.bonusPoint = payload;

    if (payload.start_date) {
      const startDate = payload.start_date.split("T")[0];
      const startTime = payload.start_date.split("T")[1].split("+")[0];
      state.bonusPoint.start_date = startDate;
      state.time.startTime = startTime;
    }

    if (payload.end_date) {
      const endDate = payload.end_date.split("T")[0];
      const endTime = payload.end_date.split("T")[1].split("+")[0];
      state.bonusPoint.end_date = endDate;
      state.time.endTime = endTime;
    }
  },
  DELETE_BONUS_POINT_SUCCESS: (state, id) => {
    state.bonusPointAll = state.bonusPointAll.filter(item => item.id !== id);
  },
  RESET_BONUS_POINT: state => {
    state.bonusPoint = {
      id: null,
      name: "",
      type: "",
      value: 0,
      max_point_per_user: 10,
      start_date: "",
      end_date: "",
      conditions: []
    };
  },
  ADD_CODITION: state => {
    state.bonusPoint.conditions = [
      ...state.bonusPoint.conditions,
      {
        evaluators: [
          {
            code: "",
            type: ""
          }
        ],
        field_condition: {
          point_by: "",
          min_stay: 1,
          max_stay: 10,
          level: ""
        }
      }
    ];
    console.log(state.bonusPoint.conditions);
  },
  DELETE_CONDITION: (state, index) => {
    state.bonusPoint.conditions.splice(index, 1);
  },
  ADD_AND: (state, indexRow) => {
    state.bonusPoint.conditions[indexRow].evaluators.push({
      code: "",
      type: "",
      field: null
    });
  },
  DELETE_AND: (state, payload) => {
    state.bonusPoint.conditions[payload.indexRow].evaluators.splice(
      payload.indexItem,
      1
    );
  },
  ADD_EVALUATOR_TAG_VALUE: (state, payload) => {
    const typeEvaluator =
      state.bonusPoint.conditions[payload.indexRow].evaluators[
        payload.indexItem
      ].type;
    if (typeEvaluator == "multi_text") {
      state.bonusPoint.conditions[payload.indexRow].evaluators[
        payload.indexItem
      ].at_least_one_of.push(payload.tag);
    }
  },
  UPDATE_EVALUATOR_VALUE: (state, payload) => {
    if (payload.values) {
      state.bonusPoint.conditions[
        payload.indexRow
      ].evaluators = state.bonusPoint.conditions[
        payload.indexRow
      ].evaluators.map((item, index) => {
        if (index === payload.indexItem) {
          if (item.type == "date_range") {
            item.gt = `${payload.values[0]}T00:00:00+07:00`;
            item.lt = `${payload.values[1]}T23:59:59+07:00`;
          } else if (item.type == "range") {
            item.gt = payload.values[0];
            item.lt = payload.values[1];
          } else if (item.type == "multi_text" || item.type == "multi_select") {
            item.at_least_one_of = payload.values;
          } else if (item.type == "select") {
            item.at_least_one_of = [payload.values];
          }
        }
        return item;
      });
    }
  },
  DELETE_EVALUATOR_VALUE: (state, payload) => {
    const typeEvaluator =
      state.bonusPoint.conditions[payload.indexRow].evaluators[
        payload.indexItem
      ].type;
    if (typeEvaluator == "multi_text") {
      const indexTag = state.bonusPoint.conditions[payload.indexRow].evaluators[
        payload.indexItem
      ].at_least_one_of.indexOf(payload.tag);
      if (indexTag > -1) {
        const tags = state.bonusPoint.conditions[payload.indexRow].evaluators[
          payload.indexItem
        ].at_least_one_of.splice(indexTag, 1);
      }
    }
  },
  CHANGE_EVALUATOR: (state, payload) => {
    var evaluator = {
      code: payload.code,
      type: payload.type
    };
    if (
      payload.type == "multi_text" ||
      payload.type == "multi_select" ||
      payload.type == "select"
    ) {
      evaluator = {
        code: payload.code,
        type: payload.type,
        at_least_one_of: []
      };
    } else if (
      payload.type == "boolean" ||
      payload.type == "date" ||
      payload.type == "number" ||
      payload.type == "text"
    ) {
      evaluator = {
        code: payload.code,
        type: payload.type,
        equal_to: null
      };
    } else if (payload.type == "date_range") {
      evaluator = {
        code: payload.code,
        type: payload.type,
        gt: null,
        lt: null
      };
    } else if (payload.type == "range") {
      evaluator = {
        code: payload.code,
        type: payload.type,
        gt: 1,
        lt: 10
      };
    }
    state.bonusPoint.conditions[
      payload.indexRow
    ].evaluators = state.bonusPoint.conditions[payload.indexRow].evaluators.map(
      (item, index) => {
        if (index === payload.indexItem) {
          return evaluator;
        } else {
          return item;
        }
      }
    );
    console.log(state.bonusPoint.conditions);
  },
  RESET_DATE: (state, type) => {
    if (type === "start") {
      state.bonusPoint.start_date = "";
      state.time.startTime = "";
    } else if (type === "end") {
      state.bonusPoint.end_date = "";
      state.time.endTime = "";
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
