import { TadaAyanaAPI } from ".";

export default {
  getList({ page = 1, filter = {}, action = "" }) {
    return TadaAyanaAPI.get(
      `/member/list?page=${page}&search=${filter.search || ""}&status=${
        filter.status || ""
      }&join_date=${filter.join_date || ""}`,
      {
        headers: { Action: action, Page: "member_list" },
      }
    );
  },
  getDetail({ cardNumber, action = "" }) {
    return TadaAyanaAPI.get(`/member/${cardNumber}`, {
      headers: { Action: action, Page: "member_detail" },
    });
  },
  getTransactionHistories({
    cardNumber,
    page = 1,
    periodStart = "",
    periodEnd = "",
    pointType = "",
    trxType = "",
    search = "",
    voucher = "",
    voucherStatus = "",
    amountMin = "",
    amountMax = "",
    propertyCode = "",
    action = "",
  }) {
    return TadaAyanaAPI.get(
      `/member/transaction/list/${cardNumber}?page=${page}&period_start=${periodStart}&period_end=${periodEnd}&point_type=${pointType}&type=${trxType}&search=${search}&voucher=${voucher}&voucher_status=${voucherStatus}&amount_min=${amountMin}&amount_max=${amountMax}&property_code=${propertyCode}`,
      {
        headers: { Action: action, Page: "member-transaction-history_list" },
      }
    );
  },
  getMembershipLevelHistories({ cardNumber, page, limit = 30, action = "" }) {
    return TadaAyanaAPI.get(
      `/member/level-adjustment/list/${cardNumber}?page=${page}&per_page=${limit}`,
      {
        headers: { Action: action, Page: `membership-level-history_list` },
      }
    );
  },
  exportTransactionHistoriesToExcel({
    cardNumber,
    periodStart = "",
    periodEnd = "",
    pointType = "",
    trxType = "",
    search = "",
    voucher = "",
    voucherStatus = "",
    amountMin = "",
    amountMax = "",
    propertyCode = "",
    action = "",
  }) {
    return TadaAyanaAPI.get(
      `/member/transaction/export/${cardNumber}?period_start=${periodStart}&period_end=${periodEnd}&point_type=${pointType}&type=${trxType}&search=${search}&voucher=${voucher}&voucher_status=${voucherStatus}&amount_min=${amountMin}&amount_max=${amountMax}&property_code=${propertyCode}`,
      {
        headers: {
          Action: action,
          Page: "member-transaction-history_list-export",
        },
      }
    );
  },
  getPointReportingList({ page = 1, filter = {}, action = "" }) {
    return TadaAyanaAPI.get(
      `/member/point-reporting/list?page=${page}&search=${
        filter.search || ""
      }&status=${filter.status || ""}&status_alt=${filter.status_alt || ""}`,
      {
        headers: { Action: action, Page: `member-point_reporting_list` },
      }
    );
  },
};
