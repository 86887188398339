<template>
  <ul class="pagination" :class="paginationClass">
    <li class="page-item prev-page" :class="{ disabled: value === 1 }">
      <a class="page-link" aria-label="Previous" @click="prevPage">
        «
      </a>
    </li>
    <li class="page-item page-pre next-page" :class="{ disabled: value === totalPages }">
      <a class="page-link" aria-label="Next" @click="nextPage">
        »
      </a>
    </li>
  </ul>
</template>
<script>
export default {
  name: "s-pagination",
  props: {
    type: {
      type: String,
      default: "primary",
      validator: value => {
        return [
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info"
        ].includes(value);
      }
    },
    pageCount: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    value: {
      type: Number,
      default: 1
    }
  },
  computed: {
    paginationClass() {
      return `pagination-${this.type}`;
    },
    totalPages() {
      return this.pageCount;
    },
    pagesToDisplay() {
      if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
        return this.totalPages;
      }
      return this.defaultPagesToDisplay;
    },
    minPage() {
      return 1;
    },
    maxPage() {
      return this.totalPages;
    }
  },
  data() {
    return {
      defaultPagesToDisplay: 5
    };
  },
  methods: {
    nextPage() {
      this.$emit("input", this.value + 1);
    },
    prevPage() {
      this.$emit("input", this.value - 1);
    }
  },
  watch: {
    perPage() {
      this.$emit("input", 1);
    },
    total() {
      this.$emit("input", 1);
    }
  }
};
</script>
