import RoleAPI from "../../api/roles";

const state = {
  role: {
    id: null,
    name: "",
    modules: [],
    users: []
  },
  pagination: {
    perPage: 0,
    totalPages: 0,
    currentPage: 0
  },
  roles: []
};

const getters = {
  haveModules: state => state.role.modules.length > 0,
  haveUnsetModule: state =>
    state.role.modules.filter(item => item.code === null).length > 0,
  getSelectedCode: state => state.role.modules.map(item => item.code),
  canAddModule: (state, getters, rootState) => {
    return (
      state.role.modules.filter(item => item.code === null).length === 0 &&
      state.role.modules.length < rootState.references.modules.length
    );
  },
  canAddAdmin: (state, getters, rootState) => {
    return state.role.users.length < rootState.users.users.length;
  },
  selectedUsers: (state, getters, rootState) => {
    if (rootState.users.users.length) {
      return state.role.users
        .map(selectedUser => {
          const users = rootState.users.users.filter(
            user => user.id === selectedUser
          );
          if (users.length) {
            return users[0];
          } else {
            return 0;
          }
        })
        .filter(selectedUser => selectedUser !== 0);
    } else {
      return [];
    }
  }
};

const actions = {
  getRoles({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      RoleAPI.getRoles({
        page: payload.page,
        action: `Admin: ${rootState.auth.name} view role list`
      })
        .then(response => {
          const data = response.data;

          commit("GET_ROLES_SUCCESS", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getRole({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      RoleAPI.getRole({
        id: payload.id,
        action: `Admin: ${rootState.auth.name} view ${payload.module}`
      })
        .then(response => {
          commit("GET_ROLE_SUCCESS", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  addModuleAccess({ commit, getters }) {
    if (getters.canAddModule) {
      commit("ADD_MODULE");
    }
  },
  deleteModuleAccess({ commit }, payload) {
    commit("DELETE_MODULE", payload.code);
  },
  changeCodeModuleAccess({ commit }, payload) {
    commit("CHANGE_CODE", payload);
  },
  updateModuleAccess({ commit }, payload) {
    commit("CHANGE_ACCESS", payload);
  },
  addUserAccess({ commit }, users) {
    commit("ADD_USER_ACCESS", users);
  },
  deleteUserAccess({ commit }, userId) {
    commit("DELETE_USER_ACCESS", userId);
  },
  updateName({ commit }, name) {
    commit("CHANGE_NAME", name);
  },
  saveRole({ state, rootState }) {
    if (state.role.id !== null) {
      return new Promise((resolve, reject) => {
        RoleAPI.putRole({
          data: state.role,
          action: `Admin: ${rootState.auth.name} update role with id: ${state.role.id}, name: ${state.role.name}`
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        RoleAPI.postRole({
          data: state.role,
          action: `Admin: ${rootState.auth.name} create role with name: ${state.role.name}`
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  deleteRole({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      RoleAPI.deleteRole({
        id: payload.id,
        action: `Admin ${rootState.auth.name} delete role with id: ${payload.id}, name: ${payload.name}`
      })
        .then(response => {
          commit("DELETE_ROLES_SUCCESS", payload.id);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  resetRole({ commit }) {
    commit("RESET_ROLE");
  }
};

const mutations = {
  GET_ROLES_SUCCESS: (state, payload) => {
    const data = payload.data.reduce((summary, value) => {
      let totalUser = value.users.length;
      let merge = value.modules.length;
      let modules = value.modules.map((item, index) => {
        const access = item.access.length
          ? item.access
              .map(value => {
                return value.charAt(0).toUpperCase() + value.slice(1);
              })
              .join(", ")
          : [];
        return {
          id: value.id,
          name: value.name,
          module: item.label,
          access: access,
          users: totalUser,
          merge: index === 0 ? merge : 0
        };
      });

      return summary.concat(modules);
    }, []);
    state.roles = data;

    const pagination = payload.meta.pagination;
    state.pagination = {
      perPage: pagination.per_page,
      totalPages: pagination.total_pages,
      currentPage: pagination.current_page
    };
  },
  DELETE_ROLES_SUCCESS: (state, roleId) => {
    const roles = state.roles.filter(item => item.id !== roleId);
    state.roles = roles;
  },
  GET_ROLE_SUCCESS: (state, data) => {
    state.role = data;
  },
  ADD_MODULE: state => {
    state.role.modules.push({
      code: null,
      access: []
    });
  },
  DELETE_MODULE: (state, code) => {
    const modules = state.role.modules.filter(item => item.code !== code);
    state.role.modules = modules;
  },
  CHANGE_CODE: (state, payload) => {
    state.role.modules.map(item =>
      item.code === payload.oldCode ? (item.code = payload.newCode) : item
    );
  },
  CHANGE_ACCESS: (state, payload) => {
    state.role.modules.map(item => {
      if (item.code === payload.code) {
        return (item.access = payload.access);
      } else {
        return item;
      }
    });
  },
  ADD_USER_ACCESS: (state, users) => {
    state.role.users = users;
  },
  DELETE_USER_ACCESS: (state, userId) => {
    const users = state.role.users.filter(item => item !== userId);
    state.role.users = users;
  },
  CHANGE_NAME: (state, name) => {
    state.role.name = name;
  },

  RESET_ROLE: (state, name) => {
    state.role = {
      id: null,
      name: "",
      modules: [],
      users: []
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
