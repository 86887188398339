import { TadaAyanaAPI } from ".";

export default {
  getList({ page = 1, subject = "", status = "", action = "" }) {
    return TadaAyanaAPI.get(
      `/campaign/rule?page=${page}&subject=${subject}&status=${status}`,{
        headers: { Action: action, Page: `campaign-rule_list` }
      });
  },
  deleteCampaign({ id, action = "" }) {
    return TadaAyanaAPI.delete(`/campaign/rule/${id}`,{
      headers: { Action: action, Page: `campaign-rule_delete` }
    });
  },
  toggleStatus({ id, status = "", action = "" }) {
    return TadaAyanaAPI.post(
      `/campaign/toggle-status/${id}`,
      { status: status },
      {
        headers: { Action: action, Page: `campaign-rule_list` }
      }
    );
  }
};
