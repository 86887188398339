import { TadaAyanaAPI } from ".";

export default {
  getTemplateList({ page = 1, limit = 10, action = "" }) {
    return TadaAyanaAPI.get(
      `notification/template?page=${page}&per_page=${limit}`,{
        headers: { Action: action, Page: `email-setting_list` }
      });
  },
  getTemplatePerLanguage({ code = code, language = language, action = "" }) {
    return TadaAyanaAPI.get(`/notification/template/${code}/${language}`,{
      headers: { Action: action, Page: `email-setting_detail` }
    });
  },
  getVariables() {
    return TadaAyanaAPI.get(`notification/template/variables`);
  },
  getTemplateVariables(code) {
    return TadaAyanaAPI.get(`notification/template/variables/${code}`);
  },
  getDetail(id) {
    return TadaAyanaAPI.get(`notification/template/${id}`);
  },
  putTemplate({data, action = ""}) {
    return TadaAyanaAPI.put(`notification/template/${data.code}`, data,{
      headers: { Action: action, Page: `email-setting_update` }
    });
  }
};
