import {TadaAyanaAPI} from ".";

export default {
  async redeemRewards({ data, action = "" }) {
    try {
      const result = await TadaAyanaAPI.post(`/member/point/redeem`, data, {
        headers: { Action: action }
      });
      return result;
    } catch (err) {
      throw err;
    }
  },
  getRewards({
    page = 1,
    limit = 9,
    name = "",
    type = "",
    category = "",
    property = "",
    action = "",
    pageName = ""
  }) {
    let config = null;

    if (action != null && action.length > 0) {
      config = { headers: { Action: action } };
    }

    if (pageName != null && pageName.length > 0) {
      config.headers["Page"] = pageName;
    }

    return TadaAyanaAPI.get(
      `/reward/catalog?page=${page}&per_page=${limit}&name=${name}&type=${type}&category_id=${category}&property=${property}&lang=EN`,
      config
    );
  },
  getReward({ id, action = "", pageName = "" }) {
    return TadaAyanaAPI.get(`/reward/catalog/${id}`, {
      headers: { Action: action, Page: pageName }
    });
  }
};
