import ReferencesAPI from "../../api/references";
import CustomFieldsAPI from "@/api/customFields";

const state = {
  modules: [],
  applyToList: [],
  fieldTypes: [],
  languages: [],
  voucherTypes: [],
  bonusTypes: [],
  menus: [],
  propertyList: [],
  categoryList: [],
  voucherNames: [],
  memberLevels: {
    silver: {
      experiences_to_achieve: 0,
      experiences_to_retain: 0,
      nights_to_achieve: 0,
      nights_to_retain: 0,
    },
    gold: {
      experiences_to_achieve: 0,
      experiences_to_retain: 0,
      nights_to_achieve: 0,
      nights_to_retain: 0,
    },
    platinum: {
      experiences_to_achieve: 0,
      experiences_to_retain: 0,
      nights_to_achieve: 0,
      nights_to_retain: 0,
    },
  },
};

const getters = {};

const actions = {
  async getModules({ commit }) {
    ReferencesAPI.getModules()
      .then((response) => {
        const modules = response.data.data || [];
        commit("GET_MODULES_SUCCESS", modules);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getApplyToList({ commit }) {
    ReferencesAPI.getFieldApplyTo()
      .then((response) => {
        const applyToList = response.data.data || [];
        commit("GET_APPLY_TO_LIST_SUCCESS", applyToList);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getFieldTypes({ commit }) {
    ReferencesAPI.getFieldTypes()
      .then((response) => {
        const fieldTypes = response.data.data || [];
        commit("GET_FIELD_TYPES_SUCCESS", fieldTypes);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getLanguages({ commit }) {
    ReferencesAPI.getLanguages()
      .then((response) => {
        const data = response.data.data || [];
        commit("GET_LANGUAGES_SUCCESS", data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getVoucherTypes({ commit }) {
    ReferencesAPI.getVoucherTypes()
      .then((response) => {
        const data = response.data.data || [];
        commit("GET_VOUCHER_TYPES_SUCCESS", data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getVoucherNames({ commit }) {
    ReferencesAPI.getVoucherNames()
      .then((response) => {
        const data = response.data.data || [];
        commit("GET_VOUCHER_NAMES_SUCCESS", data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getMemberLevels({ commit }) {
    ReferencesAPI.getMemberLevel()
      .then((response) => {
        const data = response.data.data || [];
        commit("GET_MEMBER_LEVEL_SUCCESS", data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getBonusTypes({ commit }) {
    ReferencesAPI.getBonusTypes()
      .then((response) => {
        const data = response.data.data || [];
        commit("GET_BONUS_TYPES_SUCCESS", data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getRefMenus({ commit }) {
    ReferencesAPI.getRefMenus()
      .then((response) => {
        const data = response.data.data || [];
        commit("GET_REF_MENU_SUCCESS", data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getPropertyList({ commit }) {
    ReferencesAPI.getPropertyList()
      .then((response) => {
        const data = response.data.data || [];
        commit("GET_PROPERTY_SUCCESS", data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getCategoryList({ commit }) {
    ReferencesAPI.getCategoryAll()
      .then((response) => {
        const data = response.data.data || [];
        commit("GET_CATEGORY_SUCCESS", data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  putMemberLevel({ state, rootState }) {
    let action = `Admin: ${rootState.auth.name} update member level, name: Member Level}`;

    return new Promise((resolve, reject) => {
      ReferencesAPI.putMemberLevel({
        data: state.memberLevels,
        action,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  GET_MODULES_SUCCESS: (state, modules) => {
    state.modules = modules;
  },
  GET_APPLY_TO_LIST_SUCCESS: (state, applyToList) => {
    state.applyToList = applyToList;
  },
  GET_FIELD_TYPES_SUCCESS: (state, fieldTypes) => {
    state.fieldTypes = fieldTypes;
  },
  GET_LANGUAGES_SUCCESS: (state, data) => {
    state.languages = data;
  },
  GET_VOUCHER_TYPES_SUCCESS: (state, data) => {
    state.voucherTypes = data;
  },
  GET_VOUCHER_NAMES_SUCCESS: (state, data) => {
    state.voucherNames = data;
  },
  GET_BONUS_TYPES_SUCCESS: (state, data) => {
    state.bonusTypes = data;
  },
  GET_REF_MENU_SUCCESS: (state, data) => {
    state.menus = data;
  },
  GET_CATEGORY_SUCCESS: (state, data) => {
    state.categoryList = data;
  },
  GET_PROPERTY_SUCCESS: (state, data) => {
    state.propertyList = data;
  },
  GET_MEMBER_LEVEL_SUCCESS: (state, data) => {
    state.memberLevels = data.reduce((acc, item) => {
      acc[item.code] = item;
      return acc;
    }, {});
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
