import BasicPointAPI from "../../api/basicPoint";

const state = {
  basicPointAll: [],
  basicPoint: {
    id: null,
    name: "",
    conditions: [],
    labels: [],
  },
  settings: {
    pointBy: null,
    oneBonusPointEqual: 1000,
    nightPoint: "point_only",
  },
  nightAndPointAll: [
    { code: "point_only", label: "Point only" },
    { code: "night_only", label: "Night only" },
    { code: "night_and_point", label: "Night and Point" },
  ],
};

const getters = {
  showButtonAndAtIndex: (state, getters, rootState) => (indexRow) => {
    const condition = state.basicPoint.conditions[indexRow];
    const lastEvaluator = condition.evaluators[condition.evaluators.length - 1];
    return !!(
      lastEvaluator.code &&
      condition.evaluators.length < rootState.customFields.customFieldAll.length
    );
  },
  disableOptionFieldType: (state) => (indexRow, fieldType) => {
    return (
      state.basicPoint.conditions[indexRow].evaluators
        .map((item) => item.code)
        .filter((item) => item === fieldType).length > 0
    );
  },
  inputFieldType: (state) => (indexRow, indexItem) => {
    return state.basicPoint.conditions[indexRow].evaluators[indexItem].type;
  },
};

const actions = {
  getBasicPointAll({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      BasicPointAPI.getAll({
        action: `Admin: ${rootState.auth.name} view point earning rule`,
      })
        .then((response) => {
          const data = response.data.data || [];
          commit("GET_BASIC_POINT_ALL_SUCCESS", data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getBasicPoint({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      BasicPointAPI.getDetail({
        id: payload.id,
        action: `Admin: ${rootState.auth.name} view ${payload.module}`,
      })
        .then((response) => {
          const data = response.data.data || [];
          commit("GET_BASIC_POINT_SUCCESS", data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveBasicPoint({ state, rootState }) {
    if (state.basicPoint.id !== null) {
      return new Promise((resolve, reject) => {
        BasicPointAPI.putBasicPoint({
          data: state.basicPoint,
          action: `Admin: ${
            rootState.auth.name
          } update basic point rule with id: ${
            state.basicPoint.id
          }, name: ${encodeURI(state.basicPoint.name)}`,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        BasicPointAPI.postBasicPoint({
          data: state.basicPoint,
          action: `Admin: ${
            rootState.auth.name
          } create basic point rule ${encodeURI(state.basicPoint.name)}`,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  },
  deleteBasicPoint({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      BasicPointAPI.deleteBasicPoint({
        id: payload.id,
        action: `Admin: ${
          rootState.auth.name
        } delete basic point rule with name: ${encodeURI(payload.name)}`,
      })
        .then((response) => {
          commit("DELETE_BASIC_POINT_SUCCESS", payload.id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  GET_BASIC_POINT_ALL_SUCCESS: (state, payload) => {
    state.basicPointAll = payload;
  },
  GET_BASIC_POINT_SUCCESS: (state, payload) => {
    state.basicPoint = payload;
  },
  DELETE_BASIC_POINT_SUCCESS: (state, id) => {
    state.basicPointAll = state.basicPointAll.filter((item) => item.id !== id);
  },
  RESET_BASIC_POINT: (state) => {
    state.basicPoint = {
      id: null,
      name: "",
      conditions: [],
      labels: [],
    };
    state.settings = {
      pointBy: null,
      oneBonusPointEqual: 1000,
      nightPoint: "point_only",
    };
  },
  ADD_CODITION: (state) => {
    state.basicPoint.conditions = [
      ...state.basicPoint.conditions,
      {
        evaluators: [
          {
            code: "",
            type: "",
          },
        ],
        field_condition: {
          point_by: state.settings.pointBy,
          night_point: state.settings.nightPoint,
          min_to_apply: 1,
          min_stay: 1,
          one_basic_point_equal_to: state.settings.oneBonusPointEqual,
        },
      },
    ];
  },
  DELETE_CONDITION: (state, index) => {
    state.basicPoint.conditions.splice(index, 1);
  },
  ADD_AND: (state, indexRow) => {
    state.basicPoint.conditions[indexRow].evaluators.push({
      code: "",
      type: "",
      field: null,
    });
  },
  DELETE_AND: (state, payload) => {
    state.basicPoint.conditions[payload.indexRow].evaluators.splice(
      payload.indexItem,
      1
    );
  },
  ADD_EVALUATOR_TAG_VALUE: (state, payload) => {
    const typeEvaluator =
      state.basicPoint.conditions[payload.indexRow].evaluators[
        payload.indexItem
      ].type;
    if (typeEvaluator === "multi_text") {
      state.basicPoint.conditions[payload.indexRow].evaluators[
        payload.indexItem
      ].at_least_one_of.push(payload.tag);
    }
  },
  UPDATE_EVALUATOR_VALUE: (state, payload) => {
    if (payload.values) {
      state.basicPoint.conditions[payload.indexRow].evaluators =
        state.basicPoint.conditions[payload.indexRow].evaluators.map(
          (item, index) => {
            if (index === payload.indexItem) {
              if (item.type === "date_range") {
                item.gt = `${payload.values[0]}T00:00:00+07:00`;
                item.lt = `${payload.values[1]}T23:59:59+07:00`;
              } else if (item.type === "range") {
                item.gt = payload.values[0];
                item.lt = payload.values[1];
              } else if (
                item.type === "multi_text" ||
                item.type === "multi_select"
              ) {
                item.at_least_one_of = payload.values;
              } else if (item.type === "select") {
                item.at_least_one_of = [payload.values];
              }
            }
            return item;
          }
        );
    }
  },
  DELETE_EVALUATOR_VALUE: (state, payload) => {
    const typeEvaluator =
      state.basicPoint.conditions[payload.indexRow].evaluators[
        payload.indexItem
      ].type;
    if (typeEvaluator === "multi_text") {
      const indexTag = state.basicPoint.conditions[payload.indexRow].evaluators[
        payload.indexItem
      ].at_least_one_of.indexOf(payload.tag);
      if (indexTag > -1) {
        const tags = state.basicPoint.conditions[payload.indexRow].evaluators[
          payload.indexItem
        ].at_least_one_of.splice(indexTag, 1);
      }
    }
  },
  CHANGE_EVALUATOR: (state, payload) => {
    var evaluator = {
      code: payload.code,
      type: payload.type,
    };
    if (
      payload.type === "multi_text" ||
      payload.type === "multi_select" ||
      payload.type === "select"
    ) {
      evaluator = {
        code: payload.code,
        type: payload.type,
        at_least_one_of: [],
      };
    } else if (
      payload.type === "boolean" ||
      payload.type === "date" ||
      payload.type === "number" ||
      payload.type === "text"
    ) {
      evaluator = {
        code: payload.code,
        type: payload.type,
        equal_to: null,
      };
    } else if (payload.type === "date_range") {
      evaluator = {
        code: payload.code,
        type: payload.type,
        gt: null,
        lt: null,
      };
    } else if (payload.type === "range") {
      evaluator = {
        code: payload.code,
        type: payload.type,
        gt: 1,
        lt: 10,
      };
    }
    state.basicPoint.conditions[payload.indexRow].evaluators =
      state.basicPoint.conditions[payload.indexRow].evaluators.map(
        (item, index) => {
          if (index === payload.indexItem) {
            return evaluator;
          } else {
            return item;
          }
        }
      );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
