import { TadaAyanaAPI } from ".";

export default {
  getAll() {
    return TadaAyanaAPI.get(`/point-rule/bonus/all`,{
      headers: { Page: 'point-rule-bonus_all' }
    });
  },
  getDetail({ id, action = "" }) {
    return TadaAyanaAPI.get(`/point-rule/bonus/${id}`,{
      headers: { Action: action, Page: 'point-rule-bonus_detail' }
    });
  },
  postBonusPoint({ data, action = "" }) {
    return TadaAyanaAPI.post("/point-rule/bonus", data,{
      headers: { Action: action, Page: 'point-rule-bonus_create' }
    });
  },
  putBonusPoint({ data, action = "" }) {
    return TadaAyanaAPI.put(`/point-rule/bonus/${data.id}`, data,{
      headers: { Action: action, Page: 'point-rule-bonus_update' }
    });
  },
  deleteBonusPoint({ id, action = "" }) {
    return TadaAyanaAPI.delete(`/point-rule/bonus/${id}`,{
      headers: { Action: action, Page: 'point-rule-bonus_delete' }
    });
  }
};
