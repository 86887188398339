import LevelAdjustmentAPI from "../../api/levelAdjustment";

const state = {
  adjustmentList: {
    data: [],
    meta: {
      pagination: {
        current_page: 0,
        per_page: 0,
        total_pages: 0,
        total: 0
      }
    }
  },
  levelAdjustment: {}
};

const getters = {
  tableData: state => {
    return state.adjustmentList.data;
  },
  levelAdjustmentData: state => {
    return state.levelAdjustment;
  }
};

const actions = {
  getAdjustmentList({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      LevelAdjustmentAPI.getLevelAdjustmentList({
        page: payload.page,
        limit: payload.limit,
        action: `Admin: ${rootState.auth.name} view level adjustment list`
      })
        .then(response => {
          const data = response.data || [];
          commit("GET_LEVEL_ADJUSTMENT_LIST_SUCCESS", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getLevelAdjustment({ commit, rootState }, id) {
    return new Promise((resolve, reject) => {
      LevelAdjustmentAPI.getLevelAdjustment({
        id: id,
        action: `Admin: ${rootState.auth.name} view level adjustment with id: ${id}`
      })
        .then(response => {
          const data = response.data.data || [];
          commit("GET_LEVEL_ADJUSTMENT", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createLevelAdjustment({ rootState }, payload) {
    return new Promise((resolve, reject) => {
      LevelAdjustmentAPI.createLevelAdjustment({
        data: payload.data,
        action: `Admin: ${rootState.auth.name} request level adjustment for member with card no: ${payload.data.card_number}`
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateLevelAdjustment({ rootState }, payload) {
    return new Promise((resolve, reject) => {
      LevelAdjustmentAPI.updateLevelAdjustment({
        id: payload.id,
        data: payload.data,
        action: `Admin: ${rootState.auth.name} update level adjustment with id: ${payload.id} for member with card no: ${payload.data.card_number}`
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  responseLevelAdjustment({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      switch (payload.action) {
        case "approve":
          LevelAdjustmentAPI.approvalLevelAdjustment({
            id: payload.data.id,
            action: `Admin: ${rootState.auth.name} approve level adjustment with id: ${payload.data.id} from: ${payload.data.requestor_name} for member with card no: ${payload.data.member.card_number}`
          })
            .then(response => {
              const data = response.data.data || [];
              const index = payload.index;
              commit("UPDATE_ADJUSTMENT_LIST_INDEX", { index, data });
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
          break;
        case "reject":
          LevelAdjustmentAPI.rejectLevelAdjustment({
            id: payload.data.id,
            action: `Admin: ${rootState.auth.name} reject level adjustment with id: ${payload.data.id} from: ${payload.data.requestor_name} for member with card no: ${payload.data.member.card_number}`
          })
            .then(response => {
              const data = response.data.data || [];
              const index = payload.index;
              commit("UPDATE_ADJUSTMENT_LIST_INDEX", { index, data });
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
          break;
      }
    });
  },
  deleteLevelAdjustment({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      LevelAdjustmentAPI.deleteLevelAdjustment({
        id: payload.id,
        action: `Admin: ${rootState.auth.name} delete level adjustment with id: ${payload.id} for member with card no: ${payload.member.card_number}`
      })
        .then(response => {
          commit("DELETE_LEVEL_ADJUSTMENT_SUCCESS", payload.id);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  GET_LEVEL_ADJUSTMENT_LIST_SUCCESS: (state, data) => {
    state.adjustmentList = data;
  },
  GET_LEVEL_ADJUSTMENT: (state, data) => {
    state.levelAdjustment = data;
  },
  DELETE_LEVEL_ADJUSTMENT_SUCCESS: (state, id) => {
    state.adjustmentList.data = state.adjustmentList.data.filter(
      item => item.id !== id
    );
  },
  UPDATE_ADJUSTMENT_LIST_INDEX: (state, { index, data }) => {
    state.adjustmentList.data.splice(index, 1, data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
