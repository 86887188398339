import emailCampaignAPI from "@/api/emailCampaign";
import Vue from "vue";
import moment from "moment";

const langsFactory = {
  subject: "",
  template: "",
  lang: "EN",
  image: ""
};

function emailCampaignFactory() {
  return {
    is_active: "",
    recipient: {
      type: "all",
      criteria: {
        evaluators: []
      },
      file: ""
    },
    scheduled_at: "",
    langs: [
      {
        subject: "",
        template: "",
        lang: "EN",
        image: "",
        preheader: ""
      }
    ],
    status: ""
  };
}

const state = {
  emailCampaign: {
    is_active: "",
    recipient: {
      type: "all",
      criteria: {
        evaluators: []
      },
      file: ""
    },
    scheduled_at: "",
    langs: [
      {
        subject: "",
        template: "",
        lang: "EN",
        image: "",
        preheader: ""
      }
    ],
    status: "",
    name: ""
  },
  currentLangIndex: 0,
  validation: {
    subject: false,
    description: false
  },
  memberCount: 0
};

const getters = {};

const actions = {
  uploadImage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      emailCampaignAPI
        .uploadImage({
          file: payload.data,
          directory: payload.directory
        })
        .then(response => {
          commit("SET_IMAGE", response.data.data.url);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  uploadMemberList({ commit }, data) {
    return new Promise((resolve, reject) => {
      emailCampaignAPI
        .uploadFile(data)
        .then(response => {
          commit("SET_MEMBER_LIST", response.data.data.url);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createCampaign({ commit, rootState }, status) {
    commit("SET_STATUS", status);
    commit("SET_RECIPIENT", rootState.customFields.customFieldAll);
    commit("FORMAT_SCHEDULE");
    return new Promise((resolve, reject) => {
      let subjectName = state.emailCampaign.langs.find(function(el) {
        return el.lang === "EN";
      })?.subject;

      if (subjectName != null) {
        subjectName = encodeURI(subjectName);
      }

      emailCampaignAPI
        .createCampaign({
          data: state.emailCampaign,
          action: `Admin: ${rootState.auth.name} create campaign with subject: ${subjectName}`
        })
        .then(response => {
          commit("RESET_EMAIL_CAMPAIGN");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getEmailCampaign({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      emailCampaignAPI
        .getEmailCampaign({
          id: payload.id,
          action: `Admin: ${rootState.auth.name} view ${payload.module}`
        })
        .then(response => {
          commit("SET_EMAIL_CAMPAIGN", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateCampaign({ commit, rootState }, { status, id }) {
    commit("SET_STATUS", status);
    commit("SET_RECIPIENT", rootState.customFields.customFieldAll);
    commit("FORMAT_SCHEDULE");
    return new Promise((resolve, reject) => {
      let subjectName = state.emailCampaign.langs.find(function(el) {
        return el.lang === "EN";
      })?.subject;

      if (subjectName != null) {
        subjectName = encodeURI(subjectName);
      }

      emailCampaignAPI
        .updateCampaign(
          {
            data: state.emailCampaign,
            action: `Admin: ${rootState.auth.name} update campaign with subject: ${subjectName}`
          },
          id
        )
        .then(response => {
          commit("RESET_EMAIL_CAMPAIGN");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getMemberCount({ commit }) {
    return new Promise((resolve, reject) => {
      emailCampaignAPI
        .getMemberCount()
        .then(response => {
          commit("SET_MEMBER_COUNT", response.data.data.count);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_CURRENT_LANG_INDEX: (state, i) => {
    state.currentLangIndex = i;
  },
  ADD_NEW_LANGS: (state, lang) => {
    langsFactory.lang = lang;
    state.emailCampaign.langs.push({ ...langsFactory });
  },
  SET_IMAGE: (state, url) => {
    state.emailCampaign.langs[state.currentLangIndex].image = url;
  },
  SET_MEMBER_LIST: (state, url) => {
    state.emailCampaign.recipient.file = url;
  },
  ADD_NEW_CRITERIA: state => {
    state.emailCampaign.recipient.criteria.evaluators.push({
      code: "",
      value: "",
      at_least_one_of: [],
      gt: "",
      lt: "",
      equal_to: "",
      type: ""
    });
  },
  SET_CRITERIA: (state, data) => {
    state.emailCampaign.recipient.criteria.evaluators[data.index] =
      data.evaluator;
  },
  SET_STATUS: (state, status) => {
    state.emailCampaign.status = status;
  },
  SET_RECIPIENT: (state, customFieldAll) => {
    const type = state.emailCampaign.recipient.type;
    if (type === "all") {
      state.emailCampaign.recipient = {
        type: "all"
      };
    } else if (type === "by_criteria") {
      let temp = state.emailCampaign.recipient.criteria;
      temp.evaluators.forEach(obj => {
        const propNames = Object.getOwnPropertyNames(obj);
        for (let i = 0; i < propNames.length; i++) {
          const propName = propNames[i];
          if (!obj[propName] || obj[propName].length === 0) {
            delete obj[propName];
          }
        }
        const customField = customFieldAll.find(cf => cf.code === obj.code);
        obj.field = {
          code: customField.code,
          type: customField.type.code,
          apply_to: customField.apply_to.code
        };
      });

      state.emailCampaign.recipient = {
        type: state.emailCampaign.recipient.type,
        criteria: state.emailCampaign.recipient.criteria
      };
    } else if (type === "member_list") {
      state.emailCampaign.recipient = {
        type: state.emailCampaign.recipient.type,
        file: state.emailCampaign.recipient.file
      };
    }
    if (
      state.emailCampaign.recipient.criteria &&
      state.emailCampaign.recipient.criteria.type
    ) {
      delete state.emailCampaign.recipient.criteria.type;
    }
  },
  DELETE_CRITERIA: (state, index) => {
    let temp = [...state.emailCampaign.recipient.criteria.evaluators];
    temp.splice(index, 1);
    state.emailCampaign.recipient.criteria.evaluators = [];
    Vue.nextTick(() => {
      state.emailCampaign.recipient.criteria.evaluators = temp;
    });
  },
  RESET_EMAIL_CAMPAIGN: state => {
    state.emailCampaign = Object.assign(emailCampaignFactory());
    state.emailCampaign.langs = [];
    state.emailCampaign.langs.push({ ...langsFactory });
    state.currentLangIndex = 0;
  },
  SET_EMAIL_CAMPAIGN: (state, data) => {
    if (data.recipient.type === "by_criteria") {
      data.recipient.criteria.evaluators.forEach((x, i) => {
        data.recipient.criteria.evaluators[i].code = x.field.code;
        if (x.type === "select") {
          data.recipient.criteria.evaluators[i].value =
            data.recipient.criteria.evaluators[i].at_least_one_of[0];
        } else if (x.type === "date_range") {
          data.recipient.criteria.evaluators[i].value = [
            data.recipient.criteria.evaluators[i].gt,
            data.recipient.criteria.evaluators[i].lt
          ];
        }
      });
      data.recipient.file = "";
    } else if (data.recipient.type === "member_list") {
      data.recipient.file = data.recipient.list;
      data.recipient.criteria = {
        evaluators: []
      };
    } else {
      data.recipient.file = "";
      data.recipient.criteria = {
        evaluators: []
      };
    }

    state.emailCampaign = { ...data };

    if (!state.emailCampaign.recipient.criteria) {
      state.emailCampaign.recipient.criteria = {
        evaluators: []
      };
    }
    if (!state.emailCampaign.recipient.file) {
      state.emailCampaign.recipient.file = "";
    }
  },
  SET_IS_ACTIVE: (state, type) => {
    state.emailCampaign.is_active = type;
  },
  SET_VALIDATION: (state, validation) => {
    state.validation = validation;
  },
  SET_MEMBER_COUNT: (state, data) => {
    state.memberCount = data;
  },
  FORMAT_SCHEDULE: state => {
    if (state.emailCampaign.scheduled_at !== "") {
      state.emailCampaign.scheduled_at = moment(state.emailCampaign.scheduled_at).format();
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
