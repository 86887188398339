import CategoryAPI from "../../api/categories";

const state = {
  categoryAll: [],
  category: [],
  categories: {
    data: [],
    meta: {
      pagination: {
        current_page: 0,
        per_page: 0,
        total_pages: 0,
        total: 0
      }
    }
  }
};

const getters = {
  tableData: state => {
    return state.categories.data.map(item => {
      return {
        category_id: item.category_id,
        name: item.name,
        lang: item.lang,
        languages: item.languages
      };
    });
  },
  categoryData: state => {
    return state.category;
  }
};

const actions = {
  async getCategoryAll({ commit }, activeLang) {
    CategoryAPI.getCategoryAll(activeLang)
      .then(response => {
        const data = response.data.data || [];
        commit("GET_CATEGORY_ALL_SUCCESS", data);
      })
      .catch(error => {
        console.log(error);
      });
  },
  getCategories({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      CategoryAPI.getCategories({
        page: payload.page,
        limit: payload.limit,
        action: `Admin: ${rootState.auth.name} view category list`
      })
        .then(response => {
          const data = response.data || [];
          commit("GET_CATEGORIES_SUCCESS", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getCategory({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      CategoryAPI.getCategory({
        id: payload.category_id,
        action: `Admin: ${rootState.auth.name} view ${payload.module}`
      })
        .then(response => {
          const data = response.data.data || [];
          commit("GET_CATEGORY_SUCCESS", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createCategory({ commit, state, rootState }) {
    let categoryName = state.category.find(function(el) {
      return el.lang === "EN";
    })?.name;

    if (categoryName != null) {
      categoryName = encodeURI(categoryName);
    }

    return new Promise((resolve, reject) => {
      CategoryAPI.createCategory({
        data: state.category,
        action: `Admin: ${rootState.auth.name} create category: ${categoryName}`
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateCategory({ commit, state, rootState }, category_id) {
    let categoryName = state.category.find(function(el) {
      return el.lang === "EN";
    })?.name;

    if (categoryName != null) {
      categoryName = encodeURI(categoryName);
    }

    return new Promise((resolve, reject) => {
      CategoryAPI.updateCategory({
        category_id,
        data: state.category,
        action: `Admin: ${rootState.auth.name} update category with id: ${category_id}, name: ${categoryName}`
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteCategory({ commit, rootState }, category) {
    return new Promise((resolve, reject) => {
      CategoryAPI.deleteCategory({
        category_id: category.category_id,
        action: `Admin: ${rootState.auth.name} delete category with id: ${
          category.category_id
        }, name: ${encodeURI(category.name)}`
      })
        .then(response => {
          commit("DELETE_CATEGORY_SUCCESS", category.category_id);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  addLanguageOption({ commit }, keys) {
    commit("ADD_OPTION", keys);
  },
  deleteLanguageOption({ commit }, key) {
    commit("DELETE_OPTION", key);
  }
};

const mutations = {
  GET_CATEGORY_ALL_SUCCESS: (state, data) => {
    state.categoryAll = data;
  },
  GET_CATEGORIES_SUCCESS: (state, data) => {
    state.categories = data;
  },
  GET_CATEGORY_SUCCESS: (state, data) => {
    state.category = data.map(item => {
      return {
        name: item.name,
        lang: item.lang
      };
    });
  },
  ADD_OPTION: (state, keys) => {
    keys.forEach(lang => {
      if (
        typeof state.category.find(item => item.lang === lang) === "undefined"
      ) {
        state.category.push({
          name: "",
          lang: lang
        });
      }
    });
  },
  DELETE_OPTION: (state, key) => {
    state.category = state.category.filter(item => item.lang !== key);
  },
  RESET_CATEGORY: state => {
    state.category = [];
  },
  DELETE_CATEGORY_SUCCESS: (state, category_id) => {
    state.categories.data = state.categories.data.filter(
      item => item.category_id !== category_id
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
