import CampaignAPI from "../../api/campaign";

const state = {
  campaign: {
    list: [],
    perPage: 0,
    totalPages: 0,
    currentPage: 0
  },
  filter: {
    search: "",
    status: ""
  }
};

const getters = {};

const actions = {
  getCampaignList({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      CampaignAPI.getList({
        page: payload.page,
        subject: state.filter.search,
        status: state.filter.status,
        action: `Admin: ${rootState.auth.name} view email campaign list`
      })
        .then(response => {
          commit("GET_CAMPAIGN_LIST_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  toggleCampaignStatus({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      let subject = payload.data.langs.find(function(el) {
        return el.lang === "EN";
      })?.subject;

      if (subject != null) {
        subject = encodeURI(subject);
      }

      let status;

      if (payload.data.status === "draft") {
        status = "active";
      } else {
        status = "draft";
      }
      CampaignAPI.toggleStatus({
        id: payload.data.id,
        status: status,
        action: `Admin: ${rootState.auth.name} toggle status email campaign with id: ${payload.data.id}, subject: ${subject}, status: ${status}`
      })
        .then(response => {
          const data = response.data.data || [];
          const index = payload.index;

          commit("TOGGLE_STATUS_CAMPAIGN_SUCCESS", { index, data });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteCampaign({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      let subject = payload.langs.find(function(el) {
        return el.lang === "EN";
      })?.subject;

      if (subject != null) {
        subject = encodeURI(subject);
      }

      CampaignAPI.deleteCampaign({
        id: payload.id,
        action: `Admin: ${rootState.auth.name} delete email campaign with id: ${payload.id}, subject: ${subject}`
      })
        .then(response => {
          commit("DELETE_CAMPAIGN_SUCCESS", payload.id);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  GET_CAMPAIGN_LIST_SUCCESS: (state, payload) => {
    const pagination = payload.meta.pagination;
    state.campaign = {
      list: payload.data,
      perPage: pagination.per_page,
      totalPages: pagination.total_pages,
      currentPage: pagination.current_page
    };
  },
  CLEAR_FILTER: state => {
    state.filter.search = "";
    state.filter.status = "";
  },
  DELETE_CAMPAIGN_SUCCESS: (state, id) => {
    state.campaign.list = state.campaign.list.filter(item => item.id !== id);
  },
  TOGGLE_STATUS_CAMPAIGN_SUCCESS: (state, { index, data }) => {
    state.campaign.list.splice(index, 1, data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
