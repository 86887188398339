import { TadaAyanaAPI } from ".";

export default {
  getAll({ status = 1 }) {
    return TadaAyanaAPI.get(`/custom-field/all?status=${status}`, {
      headers: { Page: "custom-field_all" }
    });
  },
  getApplyToReservation({ status = 1 }) {
    return TadaAyanaAPI.get(
      `/custom-field/all?status=${status}&apply_to[]=user&apply_to[]=reservation`,
      {
        headers: { Page: "custom-field_all" }
      }
    );
  },
  getApplyToUsers({ status = 1 }) {
    return TadaAyanaAPI.get(
      `/custom-field/all?status=${status}&apply_to[]=user&apply_to[]=transaction`,
      {
        headers: { Page: "custom-field_all" }
      }
    );
  },
  getList({ page = 1, limit = 10, status = 1, action = "" }) {
    return TadaAyanaAPI.get(
      `/custom-field?page=${page}&per_page=${limit}&status=${status}`,
      {
        headers: { Action: action, Page: "custom-field_list" }
      }
    );
  },
  getCustomField({ code, action = "" }) {
    return TadaAyanaAPI.get(`/custom-field/${code}`, {
      headers: { Action: action, Page: "custom-field_detail" }
    });
  },
  postCustomField({ data, action = "" }) {
    return TadaAyanaAPI.post("/custom-field", data, {
      headers: { Action: action, Page: "custom-field_create" }
    });
  },
  putCustomField(data) {
    return TadaAyanaAPI.put(`/custom-field/${data.code}`, data, {
      headers: { Page: "custom-field_update" }
    });
  },
  deleteCustomField({ code, action = "" }) {
    return TadaAyanaAPI.delete(`/custom-field/${code}`, {
      headers: { Action: action, Page: "custom-field_delete" }
    });
  }
};
