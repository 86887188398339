import CustomFieldsAPI from "../../api/customFields";

const state = {
  customFieldAll: [],
  list: [],
  pagination: {
    perPage: 0,
    totalPages: 0,
    currentPage: 0
  },
  customField: {
    code: "",
    title: "",
    type: "",
    apply_to: "",
    status: true,
    values: []
  }
};

const getters = {
  tableData: state => {
    return state.list.map(item => {
      return {
        code: item.code,
        title: item.title,
        applicable: item.apply_to.label,
        status: item.status ? "ON" : "OFF"
      };
    });
  },
  disableAddOption: state => {
    return (
      state.customField.values.filter(item => item === "").length > 0 ||
      state.customField.values.length === 20
    );
  }
};

const actions = {
  getCustomFieldAll({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CustomFieldsAPI.getApplyToReservation({
        status: payload.status
      })
        .then(response => {
          commit("GET_ALL_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getUserCustomFields({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CustomFieldsAPI.getApplyToUsers({
        status: payload.status
      })
        .then(response => {
          commit("GET_ALL_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getList({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      CustomFieldsAPI.getList({
        page: payload.page,
        action: `Admin: ${rootState.auth.name} view custom fields list`
      })
        .then(response => {
          commit("GET_LIST_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getCustomField({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      CustomFieldsAPI.getCustomField({
        code: payload.code,
        action: `Admin: ${rootState.auth.name} view ${payload.module}`
      })
        .then(response => {
          const data = response.data.data || [];
          commit("GET_CUSTOM_FIELD_SUCCESS", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  postCustomField({ commit, state, rootState }) {
    let action = "";
    if (state.customField.code) {
      action = `Admin: ${rootState.auth.name} update custom field with id: ${
        state.customField.code
      }, name: ${encodeURI(state.customField.title)}`;
    } else {
      action = `Admin: ${rootState.auth.name} create custom field with id: ${
        state.customField.code
      }, name: ${encodeURI(state.customField.title)}`;
    }

    return new Promise((resolve, reject) => {
      CustomFieldsAPI.postCustomField({
        data: state.customField,
        action
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteCustomField({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      CustomFieldsAPI.deleteCustomField({
        code: payload.code,
        action: `Admin: ${rootState.auth.name} delete custom field with id: ${payload.code}, name: ${encodeURI(payload.title)}`
      })
        .then(response => {
          commit("DELETE_CUSTOM_FIELDS_SUCCESS", payload.code);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  addOption({ commit }) {
    commit("ADD_OPTION");
  },
  deleteOption({ commit }, option) {
    commit("DELETE_OPTION", option);
  }
};

const mutations = {
  GET_ALL_SUCCESS: (state, payload) => {
    state.customFieldAll = payload.data;
  },
  GET_LIST_SUCCESS: (state, payload) => {
    state.list = payload.data;
    const pagination = payload.meta.pagination;
    state.pagination = {
      perPage: pagination.per_page,
      totalPages: pagination.total_pages,
      currentPage: pagination.current_page
    };
  },
  RESET_CUSTOM_FIELD: state => {
    state.customField = {
      code: "",
      type: "",
      apply_to: "",
      status: true,
      values: []
    };
  },
  ADD_OPTION: state => {
    state.customField.values.push("");
  },
  DELETE_OPTION: (state, option) => {
    state.customField.values = state.customField.values.filter(
      item => item !== option
    );
  },
  GET_CUSTOM_FIELD_SUCCESS: (state, data) => {
    state.customField = {
      code: data.code,
      title: data.title,
      type: data.type.code,
      apply_to: data.apply_to.code,
      status: data.status,
      values: data.values
    };
  },
  DELETE_CUSTOM_FIELDS_SUCCESS: (state, code) => {
    state.list = state.list.filter(item => item.code !== code);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
