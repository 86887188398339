import RedeemAPI from "@/api/redeem";

const state = {
  redeemList: [],
  redeemDetail: {},
  filter: {
    search: "",
    name: null,
    period_start: null,
    period_end: null
  },
  pagination: {},
  redeemDetailFilter: {
    property_code: "",
    e_gift_search: "",
    expired_start_date: "",
    e_gift_status: "",
    expired_end_date: ""
  },
  propertyList: []
};

const getters = {};

const mutations = {
  SET_REDEEM_LIST: (state, payload) => {
    state.redeemList = payload.data;
    state.pagination = payload.meta.pagination;
  },
  SET_REDEEM_DETAIL: (state, payload) => {
    state.redeemDetail = payload;
  },
  SET_PROPERTY_LIST: (state, payload) => {
    state.propertyList = payload;
  },
  CLEAR_FILTER: state => {
    state.filter.search = "";
    state.filter.name = null;
    state.filter.period_start = null;
    state.filter.period_end = null;
  }
};

const actions = {
  getRedeemList({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      RedeemAPI.getRedeemList({
        data: state.filter,
        page: state.pagination.current_page,
        action: `Admin: ${rootState.auth.name} view redeem list`
      })
        .then(response => {
          commit("SET_REDEEM_LIST", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  voidVoucher({ commit, rootState }, payload) {

    return new Promise((resolve, reject) => {
      let voucherName = payload.redeem.lang.find(function(el) {
        return el.lang === "EN";
      })?.name;

      if (voucherName != null) {
        voucherName = encodeURI(voucherName);
      }

      RedeemAPI.voidVoucher({
        data: payload.data,
        action: `Admin: ${rootState.auth.name} void voucher with transaction no: ${payload.redeem.transaction_no}, name: ${voucherName} from: ${payload.redeem.member.card_no}`
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getRedeemDetail({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      RedeemAPI.getRedeemDetail({
        id: payload.id,
        filter: state.redeemDetailFilter,
        action: `Admin: ${rootState.auth.name} view redeem detail with id: ${payload.id}, name: ${payload.name}`
      })
        .then(response => {
          commit("SET_REDEEM_DETAIL", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  redeemVoucher({ commit, state, rootState }, ids) {
    return new Promise((resolve, reject) => {

      const listVoucherCodeString = ids.join("-");

      const voucherName = encodeURI(state.redeemDetail.voucher_name);

      RedeemAPI.redeemVoucher({
        data: {
          codes: ids,
        },
        action: `Admin: ${rootState.auth.name} redeem voucher with transaction no: ${state.redeemDetail.transaction_no}, name: ${voucherName}, to ${state.redeemDetail.member.card_no}, codes: ${listVoucherCodeString}`
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  sendMail({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      let voucherName = payload.lang.find(function(el) {
        return el.lang === "EN";
      })?.name;

      if (voucherName != null) {
        voucherName = encodeURI(voucherName);
      }

      RedeemAPI.sendMail({
        id: payload.id,
        action: `Admin: ${rootState.auth.name} send mail with transaction no: ${payload.transaction_no}, name: ${voucherName} to member with card: ${payload.member.card_no}`
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getPrintTemplate({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      let voucherName = payload.lang.find(function(el) {
        return el.lang === "EN";
      })?.name;

      if (voucherName != null) {
        voucherName = encodeURI(voucherName);
      }

      RedeemAPI.getPrintTemplate({
        id: payload.id,
        action: `Admin: ${rootState.auth.name} print voucher with transaction no: ${payload.transaction_no}, name: ${voucherName} to member with card: ${payload.member.card_no}`
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getPropertyList({ commit }) {
    return new Promise((resolve, reject) => {
      RedeemAPI.getPropertyList()
        .then(response => {
          commit("SET_PROPERTY_LIST", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
