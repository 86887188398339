import MemberLevelBonusAPI from "../../api/memberlevelbonus";

const state = {
  levelAll: [],
  level: {
    level: "",
    type: "",
    amount: ""
  }
};

const getters = {
  tableData: state => {
    return state.levelAll.map(item => {
      switch (item.type) {
        case "percentage":
          item.type = "Percentage";
          break;
        case "fixed_amount":
          item.type = "Fixed Amount";
          break;
        case "multiplier":
          item.type = "Multiplier";
          break;
      }
      return item;
    });
  },
  levelData: state => {
    return state.level;
  }
};

const actions = {
  getLevelBonus({ commit }) {
    return new Promise((resolve, reject) => {
      MemberLevelBonusAPI.getMemberLevelBonus()
        .then(response => {
          const data = response.data.data || [];
          commit("GET_MEMBER_LEVEL_BONUS", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getLevel({ commit }, level) {
    return new Promise((resolve, reject) => {
      MemberLevelBonusAPI.getDetailLevelBonus(level)
        .then(response => {
          const data = response.data.data || [];
          commit("GET_DETAIL_LEVEL_BONUS", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateLevel({ commit, rootState }, payload) {
    let level = payload.level,
      formData = {
        type: payload.type,
        value: payload.value
      };
    return new Promise((resolve, reject) => {
      MemberLevelBonusAPI.updateMemberLevelBonus({
        level,
        data: formData,
        action: `Admin: ${rootState.auth.name} update level ${payload.level} rule`
      })
        .then(response => {
          const data = response.data.data || [];
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  GET_MEMBER_LEVEL_BONUS: (state, data) => {
    state.levelAll = data;
  },
  GET_DETAIL_LEVEL_BONUS: (state, data) => {
    state.level = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
