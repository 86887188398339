import VouchersAPI from "../../api/vouchers";
import { Loading } from "element-ui";

const state = {
  pagination: {
    perPage: 0,
    totalPages: 0,
    currentPage: 0
  },
  filter: {
    name: "",
    type: "",
    property: "",
    category: "",
    pointFrom: "",
    pointTo: ""
  },
  form: {
    name: "",
    tnc: "",
    lang: "EN"
  },
  vouchers: [],
  voucher: {
    id: null,
    sku: "",
    category_id: null,
    value: null,
    type: "",
    start_date: null,
    end_date: null,
    expiry_at: null,
    expiry_type: "none",
    expiry_number: null,
    weight: 1000,
    limit_quantity: true,
    quantity: null,
    langs: [],
    property: [],
    property_name: [],
    image: "",
    status: false
  }
};

const getters = {
  isDisableSelectLanguage: state =>
    state.form.name.length === 0 || state.form.tnc.length === 0
};

const actions = {
  getVouchers({ commit, rootState }, payload) {
    let loadingInstance = Loading.service({
      fullscreen: true
    });
    return new Promise((resolve, reject) => {
      let action = "";
      let pageName = "";
      if (payload.module !== undefined) {
        action = `Admin: ${rootState.auth.name} view ${payload.module}`;
        pageName =
          payload.pageName === "reward" ? "reward_list" : "voucher_list";
      }

      VouchersAPI.getVouchers({
        page: payload.page,
        name: state.filter.name,
        type: state.filter.type,
        category:
          payload.categoryId === undefined
            ? state.filter.category
            : payload.categoryId,
        property: state.filter.property,
        action: action,
        pageName: pageName,
        pointFrom: state.filter.pointFrom,
        pointTo: state.filter.pointTo
      })
        .then(response => {
          commit("GET_VOUCHERS_SUCCESS", response.data);
          loadingInstance.close();
          resolve(response);
        })
        .catch(error => {
          loadingInstance.close();
          reject(error);
        });
    });
  },
  getVoucher({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      VouchersAPI.getVoucher({
        id: payload.id,
        action: `Admin: ${rootState.auth.name} view ${payload.module}`,
        pageName:
          payload.pageName === "reward" ? `reward_detail` : `voucher_detail`
      })
        .then(response => {
          const data = response.data.data || [];
          commit("GET_VOUCHER_SUCCESS", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  saveVoucher({ state, rootState }) {
    let voucherName = state.voucher.langs.find(function(el) {
      return el.lang === "EN";
    })?.name;

    if (voucherName != null) {
      voucherName = encodeURI(voucherName);
    }

    if (state.voucher.id !== null) {
      return new Promise((resolve, reject) => {
        VouchersAPI.putVoucher({
          data: state.voucher,
          action: `Admin: ${rootState.auth.name} update voucher with id: ${state.voucher.id}, name: ${voucherName}`
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        VouchersAPI.postVoucher({
          data: state.voucher,
          action: `Admin: ${rootState.auth.name} create voucher: ${voucherName}`
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  deleteVouchers({ commit, state, rootState }, vouchers) {
    return new Promise((resolve, reject) => {
      let voucherName = vouchers
        .map(
          voucher =>
            voucher.langs.find(function(el) {
              return el.lang === "EN";
            })?.name
        )
        .join(", ");

      if (voucherName != null) {
        voucherName = encodeURI(voucherName);
      }

      const voucherIds = vouchers.map(voucher => voucher.id);

      VouchersAPI.deleteVouchers({
        ids: { ids: voucherIds },
        action: `Admin: ${
          rootState.auth.name
        } delete voucher with id: ${voucherIds.join(
          ", "
        )}, name: ${voucherName}`
      })
        .then(response => {
          commit("DELETE_VOUCHER_SUCCESS", voucherIds);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteVoucher({ commit, state, rootState }, voucher) {
    return new Promise((resolve, reject) => {
      let voucherName = voucher.langs.find(function(el) {
        return el.lang === "EN";
      })?.name;

      if (voucherName != null) {
        voucherName = encodeURI(voucherName);
      }

      VouchersAPI.deleteVouchers({
        ids: { ids: [voucher.id] },
        action: `Admin: ${rootState.auth.name} delete voucher with id: ${voucher.id}, name: ${voucherName}`
      })
        .then(response => {
          commit("DELETE_VOUCHER_SUCCESS", [voucher.id]);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  GET_VOUCHERS_SUCCESS: (state, payload) => {
    state.vouchers = payload.data;
    const pagination = payload.meta.pagination;
    state.pagination = {
      perPage: pagination.per_page,
      totalPages: pagination.total_pages,
      currentPage: pagination.current_page
    };
  },
  SET_NAME_TNC_LANG: (state, language) => {
    const isExist =
      state.voucher.langs.filter(item => item.lang === language).length > 0;
    const languageObj = {
      lang: language,
      name: state.form.name,
      tnc: state.form.tnc
    };
    if (isExist) {
      state.voucher.langs = state.voucher.langs.map(item => {
        if (item.lang === language) {
          return languageObj;
        } else {
          return item;
        }
      });
    } else {
      state.voucher.langs.push(languageObj);
    }
  },
  GET_NAME_TNC_LANG() {
    const activeLangs = state.voucher.langs.filter(
      item => item.lang === state.form.lang
    );
    if (activeLangs.length) {
      const activeLang = activeLangs[0];
      state.form.name = activeLang.name;
      state.form.tnc = activeLang.tnc;
    } else {
      state.form.name = "";
      state.form.tnc = "";
      state.voucher.langs.push({
        lang: state.form.lang,
        name: "",
        tnc: ""
      });
    }
  },
  SET_EXPIRY_AT_BY_DAY: (state, day) => {
    state.voucher.expiry_number = day;
  },
  SET_EXPIRY_AT_BY_DATE: (state, date) => {
    state.voucher.expiry_at = `${date}T00:00:00+07:00`;
  },
  SET_VALIDITY_START_END_DATE: (state, rangeDate) => {
    state.voucher.start_date = `${rangeDate[0]}T00:00:00+07:00`;
    state.voucher.end_date = `${rangeDate[1]}T23:59:59+07:00`;
  },
  SET_IMAGE: (state, image) => {
    state.voucher.image = image;
  },
  GET_VOUCHER_SUCCESS: (state, data) => {
    const activeLangs = data.langs.filter(
      item => item.lang === state.form.lang
    );
    if (activeLangs.length) {
      const activeLang = activeLangs[0];
      state.form.name = activeLang.name;
      state.form.tnc = activeLang.tnc;
    }
    state.voucher = {
      id: data.id,
      sku: data.sku,
      category_id: data.category_id,
      value: data.value,
      type: data.type,
      start_date: data.start_date ? data.start_date.split("T")[0] : "",
      end_date: data.end_date ? data.end_date.split("T")[0] : "",
      expiry_at: data.expiry_at ? data.expiry_at.split("T")[0] : "",
      expiry_type: data.expiry_type,
      expiry_number: data.expiry_number,
      weight: data.weight,
      limit_quantity: data.limit_quantity,
      quantity: data.quantity,
      langs: data.langs,
      property: data.property,
      property_name: data.property_name,
      image: data.image,
      status: data.status
    };
  },
  DELETE_VOUCHER_SUCCESS: (state, ids) => {
    state.vouchers = state.vouchers.filter(
      item => ids.find(element => item.id === element) == null
    );
  },
  CLEAR_FILTER: state => {
    state.filter.name = "";
    state.filter.type = "";
    state.filter.category = "";
    state.filter.property = "";
    state.filter.pointFrom = "";
    state.filter.pointTo = "";
  },
  RESET_VOUCHER: state => {
    state.form = {
      name: "",
      tnc: "",
      lang: "EN"
    };
    state.voucher = {
      id: null,
      sku: "",
      category_id: null,
      value: null,
      type: "",
      start_date: null,
      end_date: null,
      expiry_at: null,
      expiry_type: "none",
      expiry_number: null,
      langs: [],
      property: [],
      property_name: [],
      weight: 1000,
      limit_quantity: true,
      quantity: null,
      image: "",
      status: true
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
