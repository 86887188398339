import NotificationAPI from "../../api/notification";
import ConfigAPI from "../../api/config";

const state = {
  templates: {
    data: [],
    perPage: 0,
    totalPages: 0,
    currentPage: 0,
  },
  template: {
    code: "",
    description: "",
    langs: [],
  },
  variables: [],
};

const getters = {
  isSelectedLanguage: (state) => (lang) => {
    return state.template.langs.filter((item) => item.lang === lang).length > 0;
  },
  disableButton: (state) =>
    state.template.langs.filter((item) => item.lang === "").length > 0,
};

const actions = {
  getTemplates({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      NotificationAPI.getTemplateList({
        page: payload.page,
        action: `Admin ${rootState.auth.name} view email settings`,
      })
        .then((response) => {
          commit("GET_TEMPLATES_SUCCESS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getVariables({ commit }, code) {
    return new Promise((resolve, reject) => {
      NotificationAPI.getTemplateVariables(code)
        .then((response) => {
          commit("GET_VARIABLES_SUCCESS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getTemplate({ commit }, id) {
    return new Promise((resolve, reject) => {
      NotificationAPI.getDetail(id)
        .then((response) => {
          const data = response.data.data || [];
          commit("GET_TEMPLATE_SUCCESS", data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getTemplatePerLanguage({ rootState }, payload) {
    return new Promise((resolve, reject) => {
      NotificationAPI.getTemplatePerLanguage({
        code: payload.code,
        language: payload.language,
        action: `Admin ${rootState.auth.name} view template ${payload.code
          .replace(/_/g, " ")
          .toUpperCase()}`,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveTemplate({ state, rootState }) {
    return new Promise((resolve, reject) => {
      NotificationAPI.putTemplate({
        data: state.template,
        action: `Admin ${
          rootState.auth.name
        } update template ${state.template.code
          .replace(/_/g, " ")
          .toUpperCase()}`,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getEmailConfig({ rootState }, key) {
    return new Promise((resolve, reject) => {
      ConfigAPI.getConfig({ key })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateEmailConfig({ rootState }, payload) {
    let action = "";
    let pageName = "";
    if (payload.key === "mailgun_key_for_transaction") {
      action = `Admin ${rootState.auth.name} update mailgun transaction email key`;
      pageName = "transaction";
    } else {
      action = `Admin ${rootState.auth.name} update mailgun email blast key`;
      pageName = "blast";
    }
    return new Promise((resolve, reject) => {
      ConfigAPI.putConfig({
        key: payload.key,
        value: payload.value,
        action: action,
        pageName: pageName,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  GET_TEMPLATES_SUCCESS: (state, payload) => {
    const pagination = payload.meta.pagination;
    state.templates = {
      data: payload.data,
      perPage: pagination.per_page,
      totalPages: pagination.total_pages,
      currentPage: pagination.current_page,
    };
  },
  GET_VARIABLES_SUCCESS: (state, payload) => (state.variables = payload.data),
  GET_TEMPLATE_SUCCESS: (state, payload) => {
    state.template.description = payload.description;
    state.template.langs = payload.langs;
    state.template.code = payload.code;
  },
  ADD_TEMPLATE: (state) => {
    const langs = state.template.langs;
    langs.push({
      template: "",
      lang: "",
    });
    state.templates.langs = langs;
  },
  DELETE_TEMPLATE: (state, index) => {
    state.template.langs.splice(index, 1);
  },
  UPDATE_TEMPLATE_CONTENT: (state, payload) => {
    state.template.langs[payload.index].template = payload.template;
  },
  RESET_TEMPLATE: (state) => {
    state.template = {
      code: "",
      description: "",
      langs: [],
    };
  },
  UPDATE_TEMPLATE_CONTENT_BY_LANG: (state, payload) => {
    if (payload.subject.length > 0 && payload.template.length > 0) {
      if (
        state.template.langs.filter((item) => item.lang == payload.lang)
          .length > 0
      ) {
        state.template.langs = state.template.langs.map((item) => {
          if (item.lang == payload.lang) {
            item.subject = payload.subject;
            item.template = payload.template;
          }
          return item;
        });
      } else {
        state.template.langs = [...state.template.langs, payload];
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
