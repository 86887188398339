import Vue from "vue";
import moment from "moment";

Vue.filter("number", value => new Intl.NumberFormat().format(value));
Vue.filter("capitalized", function(value) {
  if (value == null) return "";
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter("formatDate", function(value) {
  if (value === undefined || value === "") return "";
  return moment(value).format("D MMM YYYY");
});

Vue.filter("formatDateTime", function(value) {
  if (value === undefined || value === "") return "";
  return moment(value).format("D MMM YYYY HH:mm:ss");
});
