import { TadaAyanaAPI } from ".";

export default {
  getAll({ onlyBasic = 0 }) {
    return TadaAyanaAPI.get(`/point-by/all?only_basic=${onlyBasic}`);
  },
  getAllNonBasic({ nonBasic = 0, action = "" }) {
    return TadaAyanaAPI.get(`/point-by/all?non_basic=${nonBasic}`, {
      headers: { Action: action, Page: 'point-by-revenue_all' }
    });
  },
  getDetail({ code, action = "" }) {
    return TadaAyanaAPI.get(`/point-by/${code}`, {
      headers: { Action: action, Page: 'point-by-revenue_detail' }
    });
  },
  postPointBy({ data, action = "" }) {
    return TadaAyanaAPI.post(
      `/point-by/${data.label.toLocaleLowerCase().replace(/\s+/g, "_")}`,
      data,
      {
        headers: { Action: action, Page: 'point-by-revenue_create' }
      }
    );
  },
  putPointBy({ data, action = "" }) {
    return TadaAyanaAPI.put(`/point-by/${data.code}`, data,{
      headers: { Action: action, Page: 'point-by-revenue_update' }
    });
  },
  deletePointBy({ code, action = "" }) {
    return TadaAyanaAPI.delete(`/point-by/${code}`, {
      headers: { Action: action, Page: 'point-by-revenue_delete' }
    });
  }
};
