import { TadaAyanaAPI } from ".";

export default {
  getModules() {
    return TadaAyanaAPI.get("/ref/modules");
  },
  getFieldTypes() {
    return TadaAyanaAPI.get("/ref/field/types");
  },
  getFieldApplyTo() {
    return TadaAyanaAPI.get("/ref/field/apply-to");
  },
  getLanguages() {
    return TadaAyanaAPI.get("/ref/lang/codes");
  },
  getVoucherTypes() {
    return TadaAyanaAPI.get("/ref/voucher/type");
  },
  getVoucherExpireTypes() {
    return TadaAyanaAPI.get("/ref/voucher/expiry/type");
  },
  getBonusTypes() {
    return TadaAyanaAPI.get("/ref/bonus-type");
  },
  getRefMenus() {
    return TadaAyanaAPI.get("/ref/menu");
  },
  getCategoryAll() {
    return TadaAyanaAPI.get(`/category?page=1&per_page=100&lang=EN`);
  },
  getPropertyList() {
    return TadaAyanaAPI.get(`/property/all?lang=EN`);
  },
  getVoucherNames() {
    return TadaAyanaAPI.get(`/ref/voucher-names`);
  },
  getMemberLevel() {
    return TadaAyanaAPI.get(`/ref/member/level`);
  },
  putMemberLevel({ data, action = "" }) {
    return TadaAyanaAPI.put("/ref/member/level", data, {
      headers: { Action: action, Page: "member-level" },
    });
  },
};
