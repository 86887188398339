import axios from "axios";
import { ACCESS_TOKEN } from "../config";
import { TadaAyanaAPI } from "../api";
import Swal from "sweetalert2";

export default function setup(store, router) {
  TadaAyanaAPI.interceptors.request.use(
    config => {
      const token = localStorage.getItem(ACCESS_TOKEN);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  TadaAyanaAPI.interceptors.response.use(
    response => {
      if (response.status === 200 || response.status === 201) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    },
    error => {
      if (error.response && error.response.status) {
        switch (error.response.status) {
          case 401:
            Swal.fire({
              icon: "error",
              title: "You don't have access",
              text: "This will redirect you to login page",
              onClose: () => {
                store.dispatch("auth/logout").then(() => {
                  router.push({ name: "Login" });
                });
              }
            });
            break;
          case 403:
            break;
          case 404:
            break;
          case 412:
            break;
          case 500:
            break;
          case 502:
            break;
        }
      }
      return Promise.reject(error);
    }
  );
}
