import AuthAPI from "../../api/auth";
import { ACCESS_TOKEN, USER_ID, USER_NAME, USER_ROLES } from "@/config";

const state = {
  token: localStorage.getItem(ACCESS_TOKEN) || "",
  name: localStorage.getItem(USER_NAME) || "",
  userId: parseInt(localStorage.getItem(USER_ID)) || 0,
  roles: JSON.parse(localStorage.getItem(USER_ROLES)) || []
};

const getters = {
  isAuthenticated: state => !!state.token
};

const actions = {
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AuthAPI.postLogin(payload.email, payload.password)
        .then(response => {
          const token = response.data.data.token.access_token;
          localStorage.setItem(ACCESS_TOKEN, token);
          localStorage.setItem(USER_NAME, response.data.data.user.name);
          localStorage.setItem(USER_ID, response.data.data.user.id);
          localStorage.setItem(USER_ROLES, JSON.stringify(response.data.data.user.roles));
          commit("LOGIN_SUCCESS", response.data.data);
          resolve(response);
        })
        .catch(error => {
          localStorage.removeItem(ACCESS_TOKEN);
          commit("LOGIN_FAILED");
          reject(error);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      localStorage.removeItem(ACCESS_TOKEN);
      commit("LOGOUT_SUCCESS");
      resolve();
    });
  }
};

const mutations = {
  LOGIN_SUCCESS: (state, payload) => {
    state.token = payload.token.access_token;
    state.name = payload.user.name;
    state.roles = payload.user.roles;
    state.userId = payload.user.id;
  },
  LOGIN_FAILED: state => {
    state.token = "";
  },
  LOGOUT_SUCCESS: state => {
    state.token = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
