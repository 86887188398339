import { TadaAyanaAPI } from "@/api/index";

const RedeemAPI = {
  getRedeemList({ data, page = 1, action = "" }) {
    let filter = {
      page: page
    };

    if (data.name != null && data.name.length > 0) {
      filter.search = data.name;
    } else if (data.search != null && data.search.length > 0) {
      filter.search = data.search;
    }

    if (data.period_start) {
      filter.period_start = data.period_start;
    }
    if (data.period_end) {
      filter.period_end = data.period_end;
    }

    const params = new URLSearchParams(filter).toString();
    return TadaAyanaAPI.get(`/redeem?${params}`, {
      headers: { Action: action, Page: `redeem_list` }
    });
  },
  voidVoucher({ data, action = "" }) {
    return TadaAyanaAPI.post(`/void`, data, {
      headers: { Action: action, Page: `voucher_void` }
    });
  },
  getRedeemDetail({ id, filter, action = "" }) {
    const params = new URLSearchParams(filter).toString();
    return TadaAyanaAPI.get(`/redeem/${id}?lang=EN&${params}`, {
      headers: { Action: action, Page: `redeem_detail` }
    });
  },
  redeemVoucher({ data, action = "" }) {
    return TadaAyanaAPI.post(`redeem`, data, {
      headers: { Action: action, Page: `redeem_create` }
    });
  },
  sendMail({ id, action = "" }) {
    return TadaAyanaAPI.post(`/redeem/send-mail/${id}`, null,{
      headers: { Action: action, Page: `redeem_send-mail` }
    });
  },
  getPrintTemplate({ id, action = "" }) {
    return TadaAyanaAPI.post(`/redeem/print/${id}`, null,{
      headers: { Action: action, Page: `redeem_print` }
    });
  },
  getPropertyList() {
    return TadaAyanaAPI.get(`/property/all?lang=EN`);
  }
};

export default RedeemAPI;
