import { TadaAyanaAPI } from ".";

export default {
  getRoles({ page, limit = 10, action = "" }) {
    return TadaAyanaAPI.get(`/role?${page}&per_page=${limit}`,{
      headers: { Action: action, Page: 'role_list' }
    });
  },
  getRole({ id, action }) {
    return TadaAyanaAPI.get(`/role/${id}`, {
      headers: { Action: action, Page: 'role_detail' }
    });
  },
  postRole({ data, action = "" }) {
    return TadaAyanaAPI.post("/role", data,{
      headers: { Action: action, Page: 'role_create' }
    });
  },
  putRole({ data, action = "" }) {
    return TadaAyanaAPI.put(`/role/${data.id}`, data,{
      headers: { Action: action, Page: 'role_update' }
    });
  },
  deleteRole({ id, action = "" }) {
    return TadaAyanaAPI.delete(`/role/${id}`,{
      headers: { Action: action, Page: 'role_delete' }
    });
  }
};
