import { TadaAyanaAPI } from ".";

export default {
  uploadImage(data) {
    return TadaAyanaAPI.post(`/upload`, data);
  },
  uploadFile(data) {
    const headers = {
      "content-type": "multipart/form-data"
    };
    return TadaAyanaAPI.post(`/upload`, data,{ headers: headers });
  },
  createCampaign({ data, action = "" }) {
    return TadaAyanaAPI.post(`/campaign/rule`, data,{
      headers: { Action: action }
    });
  },
  getEmailCampaign({ id, action = "" }) {
    return TadaAyanaAPI.get(`/campaign/rule/${id}`, {
      headers: { Action: action }
    });
  },
  updateCampaign({ data, action = "" }, id) {
    return TadaAyanaAPI.put(`/campaign/rule/${id}`, data,{
      headers: { Action: action }
    });
  },
  getMemberCount() {
    return TadaAyanaAPI.post(`/campaign/recipients/count`);
  }
};
