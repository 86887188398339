import { TadaAyanaAPI } from ".";

export default {
  getLevelAdjustmentList({ page, limit = 30, action = "" }) {
    return TadaAyanaAPI.get(`/level-adjustment?page=${page}&per_page=${limit}`,{
        headers: { Action: action, Page: `level-adjustment_list` }
      }
    );
  },
  getLevelAdjustment({ id, action = ""}) {
    return TadaAyanaAPI.get(`/level-adjustment/${id}`, {
      headers: {Action: action, Page: `level-adjustment_detail`}
    });
  },
  createLevelAdjustment({ data, action = "" }) {
    return TadaAyanaAPI.post("/level-adjustment", data,{
      headers: { Action: action, Page: `level-adjustment_create` }
    });
  },
  updateLevelAdjustment({ id, data, action = "" }) {
    return TadaAyanaAPI.put(`/level-adjustment/${id}`, data,{
      headers: { Action: action, Page: `level-adjustment_update` }
    });
  },
  approvalLevelAdjustment({ id, action = "" }) {
    return TadaAyanaAPI.put(`/level-adjustment/approval/${id}`, null, {
      headers: { Action: action, Page: `level-adjustment_approval` }
    });
  },
  rejectLevelAdjustment({ id, action = "" }) {
    return TadaAyanaAPI.put(`/level-adjustment/reject/${id}`, null,{
      headers: { Action: action, Page: `level-adjustment_reject` }
    });
  },
  deleteLevelAdjustment({ id, action = "" }) {
    return TadaAyanaAPI.delete(`/level-adjustment/${id}`,{
      headers: { Action: action, Page: `level-adjustment_delete` }
    });
  },
  
};
