import pointAdjustment from "@/api/pointAdjustment";
import axios from "axios";

const getDefaultPointAdjustment = () => ({
  member: {
    card_number: "",
    first_name: "",
    last_name: "",
    email: ""
  },
  guestName: "",
  card_number: "",
  email: "",
  place: "",
  visit_from: null,
  visit_to: null,
  room_no: "",
  check_no: "",
  transaction_date: null,
  point_type: "",
  night_value: 0,
  point_value: 0,
  bonus_point_value: 0,
  point_value_type: "",
  description: "",
  room_revenue: 0,
  fb_revenue: 0,
  other_revenue: 0,
  spa_revenue: 0,
  non_package_room_revenue: 0,
  non_package_fb_revenue: 0,
  non_package_other_revenue: 0,
  non_package_spa_revenue: 0,
  documents: [
    {
      url: "",
      mime_type: "",
      ext: ""
    }
  ]
});

const state = {
  filter: {
    search: "",
    type: ""
  },
  pointAdjustmentList: [],
  pagination: {
    current_page: 1
  },
  pointAdjustment: getDefaultPointAdjustment(),
  isConfirmationNoDuplicated: false,
  placeOfVisit: [],
  guest: [],
  guestLoading: false
};

const getters = {};

const actions = {
  getPointAdjustmentList({commit, rootState}, source) {
    return new Promise((resolve, reject) => {
      pointAdjustment
        .getPointAdjustmentList({
          filter: {...state.filter, page: state.pagination.current_page},
          source: source,
          action: `Admin: ${rootState.auth.name} view point adjustment list`
        })
        .then(response => {
          commit("SET_POINT_ADJUSTMENT_LIST", response.data);
          resolve(response);
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            console.log("Canceled");
          } else {
            reject(error);
          }
        });
    });
  },
  searchByConfirmationNo({commit, rootState}, confirmationNo) {
    return new Promise((resolve, reject) => {
      pointAdjustment
        .searchByConfirmationNo(confirmationNo)
        .then(response => {
          commit("SET_SEARCH_BY_CONFIRMATION", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getPointAdjustmentDetail({commit, rootState}, id) {
    return new Promise((resolve, reject) => {
      pointAdjustment
        .getPointAdjustmentDetail({
          id: id,
          action: `Admin: ${rootState.auth.name} view point adjustment with id: ${id}`
        })
        .then(response => {
          commit("SET_POINT_ADJUSTMENT", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getPlaceOfVisit({commit}) {
    return new Promise((resolve, reject) => {
      pointAdjustment
        .getPlaceOfVisit()
        .then(response => {
          commit("SET_PLACE_OF_VISIT", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  searchGuest({commit}, payload) {
    commit("SET_GUEST_LOADING", true);
    return new Promise((resolve, reject) => {
      pointAdjustment
        .searchGuest(payload)
        .then(response => {
          commit("SET_GUEST", response.data.data);
          commit("SET_GUEST_LOADING", false);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createPointAdjustment({commit, rootState}) {
    return new Promise((resolve, reject) => {
      pointAdjustment
        .createPointAdjustment({
          data: state.pointAdjustment,
          action: `Admin: ${rootState.auth.name} request point adjustment for member with card no: ${state.pointAdjustment.card_number}`
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updatePointAdjustment({commit, rootState}, id) {
    return new Promise((resolve, reject) => {
      pointAdjustment
        .updatePointAdjustment({
          id,
          data: state.pointAdjustment,
          action: `Admin: ${rootState.auth.name} update point adjustment with id: ${id}, for member with card no: ${state.pointAdjustment.card_number}`
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deletePointAdjustment({commit, rootState}, payload) {
    return new Promise((resolve, reject) => {
      console.log(`Admin: ${rootState.auth.name} delete point adjustment with id: ${payload.id}, for member with card no: ${payload.member.card_number}`);

      pointAdjustment
        .deletePointAdjustment({
          id: payload.id,
          action: `Admin: ${rootState.auth.name} delete point adjustment with id: ${payload.id}, for member with card no: ${payload.member.card_number}`
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  approvePointAdjustment({commit, rootState}, id) {
    return new Promise((resolve, reject) => {
      pointAdjustment
        .approvePointAdjustment({
          id,
          action: `Admin: ${rootState.auth.name} approve point adjustment with id: ${id}, from: ${state.pointAdjustment.requestor_name} for member with card no: ${state.pointAdjustment.card_number}`
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_POINT_ADJUSTMENT_LIST: (state, payload) => {
    state.pointAdjustmentList = payload.data;
    state.pagination = payload.meta.pagination;
  },
  SET_SEARCH_BY_CONFIRMATION: (state, payload) => {
    state.isConfirmationNoDuplicated = payload.data != null;
  },
  SET_POINT_ADJUSTMENT: (state, payload) => {
    state.pointAdjustment = payload.data;
    state.pointAdjustment.guestName =
      payload.data.member.first_name + " " + payload.data.member.last_name;
    state.pointAdjustment.email = payload.data.member.email;
    state.pointAdjustment.card_number = payload.data.member.card_number;
  },
  RESET_POINT_ADJUSTMENT: state => {
    state.pointAdjustment = getDefaultPointAdjustment();
  },
  SET_PLACE_OF_VISIT: (state, payload) => {
    state.placeOfVisit = payload;
  },
  ADD_MORE_DOCUMENT: (state, payload) => {
    state.pointAdjustment.documents.push(payload);
  },
  REMOVE_DOCUMENT: (state, payload) => {
    state.pointAdjustment.documents.splice(payload, 1);
  },
  SET_GUEST: (state, payload) => {
    state.guest = payload;
  },
  SET_GUEST_LOADING: (state, payload) => {
    state.guestLoading = payload;
  },
  SET_MEMBER_DETAIL: (state, payload) => {
    if (payload.name) {
      state.pointAdjustment.guestName = payload.name;
    }
    state.pointAdjustment.card_number = payload.card_number;
    state.pointAdjustment.email = payload.email;
  },
  ADD_UPLOADED_DOCUMENT: (state, payload) => {
    payload.data.mime_type = payload.data.mime;
    state.pointAdjustment.documents[payload.index] = payload.data;
    state.pointAdjustment.documents = [...state.pointAdjustment.documents];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
