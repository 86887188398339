import { TadaAyanaAPI } from ".";

export default {
  getCategoryAll(language) {
    return TadaAyanaAPI.get(`/category/all?lang=${language}`);
  },
  getCategories({ page, limit = 30, language = "EN", action = "" }) {
    return TadaAyanaAPI.get(
      `/category?page=${page}&per_page=${limit}&lang=${language}`,{
        headers: { Action: action, Page: `category_list` }
      });
  },
  getCategory({ id, action = "" }) {
    return TadaAyanaAPI.get(`/category/${id}`, {
      headers: { Action: action, Page: `category_detail`}
    });
  },
  createCategory({ data, action = "" }) {
    return TadaAyanaAPI.post(`/category`, data,{
      headers: { Action: action, Page: `category_create` }
    });
  },
  updateCategory({ category_id, data, action = "" }) {
    return TadaAyanaAPI.put(`/category/${category_id}`, data,{
      headers: { Action: action, Page: `category_update` }
    });
  },
  deleteCategory({ category_id, action = "" }) {
    return TadaAyanaAPI.delete(`/category/${category_id}`,{
      headers: { Action: action, Page: `category_delete` }
    });
  }
};
